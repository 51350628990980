import {
  getCommonParamsItemLabel
  // getGlobalParamsListItemLabel
} from '@/utils/common'

export const contentTableConfig = {
  // title: '('param.app-application-list')',
  propList: [
    {
      prop: 'name',
      label: 'user.tenant-name'
    },
    {
      prop: 'superiorTenant',
      label: 'tenant.superior-tenant'
    },
    {
      prop: 'industry',
      label: 'tenant.industry',
      formatter: (row) => {
        return getCommonParamsItemLabel(row, 'industry')
      }
    },
    // {
    //   prop: 'customer',
    //   label: 'dashboard.customer-title-info'
    // },
    {
      prop: 'description',
      label: 'general.description'
    },
    {
      prop: 'createdTime',
      slotName: 'formatZeroTime',
      label: 'common.app-creation-time'
    },
    {
      label: 'common.app-operation',
      width: '420',
      slotName: 'handler'
    }
  ],
  showIndexColumn: true
}
