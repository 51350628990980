<template>
  <div class="models">
    <page-search
      ref="pageSearchRef"
      :searchFormConfig="searchFormConfigRef"
      :pageTitle="$t('menu.tenant-overview')"
      @resetBtnClick="handleResetClick"
      @queryBtnClick="handleQueryClick"
    >
    </page-search>

    <page-content
      style="margin-top: 10px"
      ref="pageContentRef"
      v-bind="tableOptionsConfig"
      @editBtnClick="handleEditData"
      @viewBtnClick="handleViewData"
      @newBtnClick="handleNewData"
      @pageInfoChange="pageInfoChangeAction"
    >
      <template #otherHandler="scope">
        <el-button
          v-if="isRelation"
          class="opt-btn"
          icon="el-icon-connection"
          size="mini"
          plain
          @click="handleConnection(scope.row)"
          >Relation</el-button
        >
      </template>
    </page-content>

    <el-drawer
      :size="'50%'"
      v-model="drawer"
      title="Branch Tenant"
      :direction="direction"
      :before-close="handleClose"
    >
      <div style="text-align: right">
        <el-switch v-model="horizontal"></el-switch
        ><span style="margin-left: 10px">Horizontal</span>
      </div>
      <vue3-tree-org
        :data="subTenantList"
        center
        :default-expand-level="1"
        :horizontal="horizontal"
        :collapsable="collapsable"
        :label-style="style"
        :only-one-node="onlyOneNode"
        :clone-node-drag="cloneNodeDrag"
        :before-drag-end="beforeDragEnd"
        @on-node-drag="nodeDragMove"
        @on-node-drag-end="nodeDragEnd"
        @on-contextmenu="onMenus"
        @on-expand="onExpand"
        @on-node-dblclick="onNodeDblclick"
        @on-node-click="onNodeClick"
        :props="{
          label: 'name'
        }"
      ></vue3-tree-org>
    </el-drawer>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'

import pageSearch from '@/components/PageSearch/page-search'
import pageContent from '@/components/PageContent/page-content.vue'

import { searchFormConfig } from './config/search'
import { contentTableConfig } from './config/table'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { usePermission } from '@/hooks/use-permission'

// 表格基础配置项
const tableOptions = {
  moduleName: 'tenant', // 所属的模块
  pageName: 'tenant', // 所属的页面
  contentTableConfig, // 表格列的基础配置
  pageAction: 'tenant/getTenantLists', // 获取表格的Action
  deleteRecordAction: 'tenant/handleDeleteTenant', // 删除record的Action
  pageCountGetter: 'tenant/pageListCount', // 获取表格记录数据总和getters
  pageListGetter: 'tenant/pageListData' // 获取表格列表数据getters
}

const searchFormConfigRef = ref(searchFormConfig)
const store = useStore()
const tableOptionsConfig = computed(() => tableOptions)
const drawer = ref(false)
const pageContentRef = ref('')
const pageSearchRef = ref('')
// const baseFormRef = ref(null)
const subTenantList = ref([])

const horizontal = ref(true)
const collapsable = ref(true)
const onlyOneNode = ref(false)

const style = ref({
  background: '#fff',
  color: '#5e6d82'
})

// // 获取页面操作的权限
const isRelation = usePermission(
  tableOptions.moduleName,
  tableOptions.pageName,
  'relation'
)

const handleQueryClick = (queryInfo) => {
  console.log(queryInfo)
  // 调用page-search中的getPageData方法
  pageContentRef.value.getPageData(queryInfo)
}
const handleResetClick = () => {
  pageContentRef.value.getPageData()
}

const router = useRouter()
const handleViewData = (id) => {
  router.push(`/tenant/view/${id}`)
}

const handleEditData = (id) => {
  router.push(`/tenant/edit/${id}`)
}

const handleNewData = () => {
  router.push(`/tenant/new`)
}

const pageInfoChangeAction = () => {
  const formData = pageSearchRef.value.getCurrentFieldValues()
  pageContentRef.value.getPageData(formData)
}

const handleConnection = async (row) => {
  drawer.value = true
  console.log(row.id)
  const res = await store.dispatch('tenant/getSubTenantListById', row.id)
  subTenantList.value = res[0] || []
}
</script>
