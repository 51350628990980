import {
  // getParamsOptionsAsync,
  getCommonParamsOptions
} from '@/utils/common.js'
export const searchFormConfig = {
  formItems: [
    {
      field: 'name',
      type: 'input',
      label: 'user.tenant-name'
    },
    {
      field: 'industry',
      type: 'select',
      label: 'tenant.industry',
      isResolveGlobalParams: true,
      options: [],
      handler: () => getCommonParamsOptions('industry')
    },
    // {
    //   field: 'customer',
    //   type: 'input',
    //   label: 'dashboard.customer-title-info'
    // },
    {
      field: 'creationDate',
      type: 'datepicker',
      label: 'general.creation-date',
      otherOptions: {
        type: 'daterange'
      },
      dateFieldName: ['startDateTime', 'endDateTime']
    }
    // {
    //   field: 'superiorTenant',
    //   type: 'treeSelect',
    //   label: 'tenant.superior-tenant',
    //   isResolveGlobalParams: true,
    //   otherOptions: {
    //     props: {
    //       multiple: true
    //     }
    //   },
    //   options: [],
    //   handler: () =>
    //     getParamsOptionsAsync('tenantList', {
    //       isNeedPreFix: false
    //     })
    // }
  ]
}
